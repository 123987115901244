import Cookies from "universal-cookie";
import {
  SKYBRARY_USER_TOKEN,
  SKYBRARY_USER_REFRESH_TOKEN,
  SKYBRARY_CLEVER_TOKEN,
  SKYBRARY_CLEVER_INTERNAL_SECTION_ID,
  SKYBRARY_TOKEN_EXPIRES_IN,
} from "@utils/constants";

const encodeValue = (value: string) => {
  return btoa(encodeURIComponent(JSON.stringify(value)));
};

const decodeValue = (value: string) => {
  try {
    return JSON.parse(decodeURIComponent(atob(value)));
  } catch (error: unknown) {
    return {};
  }
};

/**
 * Encode password before send it to Skybrary API to handle cases where is needed to migrate password for EDU users.
 *
 * @param value
 */
const encodePassword = (value: string) => {
  return btoa(value);
};

/**
 * Decodes value generated in function defined above.
 *
 * @param value
 */
const decodePassword = (value: string) => {
  try {
    return atob(value);
  } catch (error: unknown) {
    return {};
  }
};

const LogoutHelper = () => {
  const cookies = new Cookies();
  const cookieParams = {
    path: "/",
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  };
  cookies.remove(SKYBRARY_USER_TOKEN, cookieParams);
  cookies.remove(SKYBRARY_TOKEN_EXPIRES_IN, cookieParams);
  cookies.remove(SKYBRARY_USER_REFRESH_TOKEN, cookieParams);
  cookies.remove(SKYBRARY_CLEVER_TOKEN, cookieParams);
  cookies.remove(SKYBRARY_CLEVER_INTERNAL_SECTION_ID, cookieParams);
  //cookies.remove(SKYBRARY_CLEVER_DISTRICT_TOKEN, cookieParams)
  cookies.remove("Drupal.visitor.rrkuser", cookieParams);
  localStorage.clear();
  sessionStorage.clear();
  localStorage.setItem(
    "logoutMessage",
    "Your session has expired, please log in again.",
  );
  window.location.reload();
};

const getSentryTracesSampleRate = () => {
  if (process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE !== undefined) {
    return parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE);
  } else {
    return 0.5;
  }
};

const getSentryReplaysSessionSampleRate = () => {
  if (process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE !== undefined) {
    return parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE);
  } else {
    return 0.1;
  }
};

const getSentryReplaysOnErrorSampleRate = () => {
  if (process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE !== undefined) {
    return parseFloat(
      process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
    );
  } else {
    return 0.5;
  }
};

const getSentryEnable = () => {
  return process.env.REACT_APP_SENTRY_ENABLE === "true";
};

// For pages where the user is logged in we will use the isCleverUser() function and for the rest of the cases the isCleverTheme(userContext, cleverContext).
const isCleverTheme = (userContext: any, cleverContext: any): boolean => {
  const { pathname } = window.location;

  const pathsToExclude = [
    "/try-it-free",
    "/purchase",
    "/gift",
    "/set-password",
  ];
  const pathsToInclude = ["/student/classroom/access", "/user"];

  const isExcludedPath = pathsToExclude.some((path) =>
    pathname.startsWith(path),
  );
  const isIncludedPath = pathsToInclude.some((path) =>
    pathname.startsWith(path),
  );

  return (
    (!userContext.isLoggedIn && !isExcludedPath) ||
    cleverContext.isCleverUser() ||
    isIncludedPath
  );
};

const getCurrentTheme = (userContext: any, cleverContext: any): string => {
  return isCleverTheme(userContext, cleverContext)
    ? "sky-theme"
    : "classic-theme";
};

export {
  LogoutHelper,
  encodeValue,
  decodeValue,
  encodePassword,
  decodePassword,
  getSentryTracesSampleRate,
  getSentryReplaysSessionSampleRate,
  getSentryReplaysOnErrorSampleRate,
  getSentryEnable,
  getCurrentTheme,
  isCleverTheme,
};
