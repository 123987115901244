import React, { createContext, useState, useContext, ReactNode } from 'react';
import PageSpinner from '../components/PageSpinner';

interface SpinnerContextProps {
  showSpinner: () => void;
  hideSpinner: () => void;
  isSpinnerVisible: boolean;
}

export const SpinnerContext = createContext<SpinnerContextProps | undefined>(undefined);

export const SpinnerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isSpinnerVisible, setSpinnerVisible] = useState(false);

  const showSpinner = () => setSpinnerVisible(true);
  const hideSpinner = () => setSpinnerVisible(false);

  return (
    <SpinnerContext.Provider value={{ showSpinner, hideSpinner, isSpinnerVisible }}>
      {isSpinnerVisible && <PageSpinner />}
      {children}
    </SpinnerContext.Provider>
  );
};

export const useSpinner = (): SpinnerContextProps => {
  const context = useContext(SpinnerContext);
  if (!context) {
    throw new Error('useSpinner must be used within a SpinnerProvider');
  }
  return context;
};
