/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useRef, useEffect } from "react";
import FormCreditCard from "../../components/Forms/purchase/FormCreditCard";
import {
  formSteps,
  FormPurchaseContext,
} from "../../context/Forms/purchaseContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { createGuestPaymentIntent } from "../../api/rifService";
import UserContext from "../../context/user";
import CleverContext from "../../context/clever";
import GiftContext from "../../context/Forms/giftContext";
import Header from "../../components/header/Header";
import { BACK_URL, registerText } from "../../utils/constants";
import Renewals from "../../components/Dashboard/Admin/AdminSections/Renewals";
import { isCleverTheme } from "../../utils/helpers";

const CardInfo = (props: { page: string; form: string }) => {
  const apiKey = process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY as string;
  const stripePromise = loadStripe(apiKey);
  const [clientSecret, setClientSecret] = useState("");
  const userContext: any = useContext(UserContext);
  const cleverContext = useContext(CleverContext)
  const purchaseContext: any = useContext(FormPurchaseContext);
  const didPaymentIntentRef = useRef(false);

  const isCleverSession = isCleverTheme(userContext, cleverContext);
  let email = "";
  let ammount = 0;
  const giftContext: any = useContext(GiftContext);
  const orderContext: any = useContext(FormPurchaseContext);
  let activeStep = 0;
  const [errorSubmit, setErrorSubmit] = useState(false);

  let previousPath = BACK_URL;
  let buttonText = "<< Back";

  if (giftContext.prevUrl) {
    previousPath = giftContext.prevUrl;
    buttonText = "<< Back to Dashboard";
  }
  const hasSubscriptionRenewals = "admin_purchase" && orderContext.thirdStep.subscriptionRenewals?.length > 0

  useEffect(() => {
    document.title = "Checkout | Reading Is Fundamental";
    let description = '';
    if (props.page !== "gift") {
      description = 'Purchase Edu';
      if (didPaymentIntentRef.current === false) {
        if (
          props.page === "purchase" ||
          props.page === "try_it_free_purchase_email"
        ) {
          if (purchaseContext.thirdStep.orderTotal > 0) {
            ammount = purchaseContext.thirdStep.orderTotal * 100;
            email = purchaseContext.registerData.email;
          }
        } else if (props.form === "try_it_free_purchase") {
          activeStep = 2;
          if (giftContext.secondStep.orderTotal > 0) {
            ammount = giftContext.secondStep.orderTotal * 100;
            email = userContext.userData.emailAddress;
          }
        } else if (props.page === "admin_purchase") {
          ammount = purchaseContext.thirdStep.orderTotal * 100;
          email = userContext.userData.emailAddress;
        }
      }
    } else {
      if (giftContext.secondStep.orderTotal > 0) {
        ammount = giftContext.secondStep.orderTotal * 100;
        email = giftContext.firstStep.buyerEmail;
        description = 'Purchase of edu gift.';
      }
    }
    if (email.length > 0) {
      createGuestPaymentIntent(ammount, email, description).then((response) => {
        setClientSecret(response.data.data.clientSecret);
      });
    }
  }, [errorSubmit]);

  const options: any = {
    clientSecret,
  };

  const handleError = () => {
    setErrorSubmit(true);
  };

  return (
    <div className="purchase-page">
      <div className="container">
        <div className="row mx-auto main-content">
          {clientSecret && (
            <Elements stripe={stripePromise} options={options}>
              <FormCreditCard
                formSteps={formSteps[props.page as keyof Object]}
                clientSecret={clientSecret}
                page={props.page}
                onSubmitError={handleError}
                errorSubmitting={errorSubmit}
              />
            </Elements>
          )}
          {!clientSecret && (
            <>
              {props.page !== "admin_purchase" && (
                <Header
                  steps={formSteps[props.page as keyof Object]}
                  activeStep={activeStep}
                  previousPath={previousPath}
                  buttonText={buttonText}
                />
              )}
              <div className="register-form mx-auto placeholder-glow">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="login-form">
                        <h1 className="step-title text-center">
                          {
                            registerText[props.page as keyof Object]
                              .purchaseStepsText[2]
                          }
                        </h1>
                        {!isCleverSession && (
                        <div className="step-info text-center">
                          {props.page === "gift" && (
                            <span>
                              Your gift is almost on its way! <br />
                            </span>
                          )}
                          {props.page === "admin_purchase" ? (
                            <>
                              <div>
                                Purchase multiple plans or add subscriptions at
                                anytime! All plans include UNLIMITED access to
                                the service and connection to the Teacher
                                Dashboard with 40 lesson plans.
                              </div>
                              <br />
                              <div>
                                CLASSROOM PLAN: Provides up to 35 seats or
                                individual logins per class.
                              </div>
                              <br />
                              <div>
                                SCHOOL PLAN: A bundle of 10 Classroom Plans for
                                a discounted price. Each provides up to 35 seats
                                per class, for a total of 350 individual logins.
                              </div>
                              <br />
                              <div>
                                Need more? For larger volume or district
                                purchases, contact skybrary@RIF.org
                              </div>
                            </>
                          ) : (
                            <>
                              <span>
                                Review your order details and enter credit card
                                info in the boxes below.
                              </span>
                              <br />
                              <br />
                            </>
                          )}
                        </div>
                        )}
                        <h1 className="order">Order detail</h1>
                        {hasSubscriptionRenewals &&
                            <Renewals
                              remove={false}
                            />
                          }
                        <div className="mx-0 my-3 row w-100">
                          <label
                            className="form-label col-form-label text-end p-0"
                            htmlFor="classroomPlan"
                          >
                            Classroom Plan <em>($ 179 )</em>
                          </label>
                          <span className="placeholder col-3 mx-auto"></span>
                        </div>
                        <div className="mx-0 my-3 row w-100">
                          <label
                            className="form-label col-form-label text-end p-0"
                            htmlFor="schoolPlan"
                          >
                            School Plan <em>($ 1450 )</em>
                          </label>
                          <span className="placeholder col-3 mx-auto"></span>
                        </div>
                        <div className="purchase-total mx-auto">
                          <div className="single_pack">
                            Classroom Plan(s)
                            <span className="total">$0.00</span>
                          </div>
                          <div className="super_pack">
                            School Plan(s)
                            <span className="total">$0.00</span>
                          </div>
                          <div className="discount">
                            Discount
                            <span className="total">$0.00</span>
                          </div>
                          <div className="order-total">
                            Order Total
                            <span className="total">$0.00</span>
                          </div>
                        </div>
                        <h1 className="order credit-card-info mt-3">
                          Credit Card Info
                        </h1>
                        <span className="placeholder credit-card-placeholder col-12"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardInfo;
