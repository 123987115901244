import React from "react";
import { formSteps, FormPurchaseContext } from "../../context/Forms/purchaseContext";
import FormSchoolInfo from "../../components/Forms/purchase/FormSchoolInfo";

 
const Info = (props: any) => {
  return (
    <div className="purchase-page">
			<div className="container">
				<div className="row mx-auto main-content">
					<FormSchoolInfo page={props.page} form={props.form} formSteps={formSteps[props.form]} />
				</div>
			</div>
		</div>
  )
}

export default Info;