import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {FormPurchaseProvider} from "./context/Forms/purchaseContext";
import {UserProvider} from "./context/user";
import {InformationProvider} from "./context/dashboardInfo";
import {GiftProvider} from "./context/Forms/giftContext";
import {CleverProvider} from "./context/clever";
import * as Sentry from "@sentry/react";
import {
  getSentryReplaysOnErrorSampleRate,
  getSentryReplaysSessionSampleRate,
  getSentryTracesSampleRate,
  getSentryEnable
} from "./utils/helpers";
import { ThemeProvider } from "./providers/ThemeProvider";
import { SpinnerProvider } from "./providers/SpinnerProvider";

if (getSentryEnable()) {
  const trackedSites = process.env.REACT_APP_SENTRY_MONITOR;
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV_MODE,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: trackedSites.replace(/\s+/g, '').split(',').filter(site => site !== ""),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    // Capture 100% of the transactions, reduce in production!
    tracesSampleRate: getSentryTracesSampleRate(),
    // Session Replay
    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysSessionSampleRate: getSentryReplaysSessionSampleRate(),
    // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: getSentryReplaysOnErrorSampleRate(),
  });
}

ReactDOM.render(
  <React.StrictMode>
    <SpinnerProvider>
      <UserProvider>
        <CleverProvider>
          <FormPurchaseProvider>
            <InformationProvider>
              <GiftProvider>
                <ThemeProvider>
                  <App/>
                </ThemeProvider>
              </GiftProvider>
            </InformationProvider>
          </FormPurchaseProvider>
        </CleverProvider>
      </UserProvider>
    </SpinnerProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
