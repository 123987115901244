import FormRegister from "../user/register";

const TryItFree = (props: {form: string, page: string }) => {
  return (
    <>
      <div className="purchase-page">
        <div className="container">
          <div className="row mx-auto main-content">
            <FormRegister page={props.page} form={props.form} userInfo="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default TryItFree;
