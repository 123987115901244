import SubscriptionInfoForm from "../../../components/Forms/gift/Redeem/SubscriptionInfoForm";

const SubscriptionInfo = (props: { page: string; }) => {
  return (
    <div className="purchase-page gift">
      <div className="container">
        <div className="row mx-auto main-content">
          <SubscriptionInfoForm page={props.page} />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionInfo;
