import Header from "../../components/Dashboard/Common/Header/Header";
import Body from "../../components/Dashboard/Common/Body";
import GiftContext from "../../context/Forms/giftContext";
import { useContext } from "react";
import UserContext from "../../context/user";

function Dashboard(props: any) {
  const giftContext = useContext(GiftContext);
  const userContext = useContext(UserContext);
  let rolPath = "/adm/dashboard";


  if (userContext.userData.type === "edu-teacher") {
    rolPath = "/teachers/dashboard";
  } else if (userContext.userData.type === "edu-parent") {
    rolPath = "/parent/dashboard";
  }

  giftContext.setLastUrl(rolPath)

  return (
    <div className={`container dashboard-wrapper mx-auto ${props.rol}-dashboard`}>
      <Header rol={props.rol} />
      <Body> {props.children} </Body>
    </div>
  );
}

export default Dashboard;
