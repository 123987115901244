
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { getCurrentTheme, isCleverTheme } from '../utils/helpers';
import { useCleverContext } from '../context/clever';
import { useUserContext } from '../context/user';

interface ThemeContextProps {
  theme: string;
  setCustomTheme: (value: string) => void;
}

const ThemeContext = createContext<ThemeContextProps | null>(null);

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const userContext = useUserContext()
  const cleverContext = useCleverContext();
  const [theme, setTheme] = useState(getCurrentTheme(userContext, cleverContext));

  const setCustomTheme = (value: string) => setTheme(value);

  useEffect(() => {
    const currentTheme = getCurrentTheme(userContext, cleverContext);
    setTheme(currentTheme);
  }, [isCleverTheme(userContext, cleverContext)]);

  return (
    <ThemeContext.Provider value={{theme, setCustomTheme}}>
      <div className={`${theme}`}>{children}</div>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = (): ThemeContextProps => {
  const context = useContext(ThemeContext);

  if (!context) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }

  return context;
};