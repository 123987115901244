import EditPasswordForm from "../../components/Forms/get-started/EditPasswordForm";
import Footer from "../../components/Footer/Footer";

const EditPassword = () => {  
  return (
    <div className="edit-password-page">
      <EditPasswordForm />
      <Footer/>
    </div>
  );
};

export default EditPassword;
