import {useContext, useEffect} from "react";
import ParentSignInForm from "../../components/Forms/parents/WelcomeStudentsForm";
import NavBar from "../../components/NavBar";
import ButtonMenu from "../../components/ButtonMenu";
import Footer from "../../components/Footer/Footer";
import {isCleverTheme} from "../../utils/helpers";
import UserContext from "../../context/user";
import CleverProvider from "../../context/clever";

const WelcomeStudents = () => {
  useEffect(() => {
    document.title = "Welcome Students | Reading Is Fundamental";
  }, []);

  const userContext = useContext(UserContext);
  const cleverContext = useContext(CleverProvider)

  return (
    <div className="sign-in-page parents-welcome">
      <NavBar/>
      {
        !isCleverTheme(userContext, cleverContext) && <ButtonMenu/>
      }
      <ParentSignInForm/>
      <Footer/>
    </div>
  );
};

export default WelcomeStudents;
