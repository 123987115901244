import { useParams } from "react-router-dom";
import FormRegister from "../user/register";
import { useEffect } from 'react'

const Purchase = (props: { form: string, page: string }) => {
	const params : any = useParams();
	let userInfo = "";

	if (params !== null) {
		userInfo = params.user;
	}

  useEffect(() => {
    document.title = 'Purchase for your school class or school | Reading Is Fundamental';
  });

	return (
		<div className="purchase-page">
			<div className="container">
				<div className="row mx-auto main-content">
					<FormRegister page={props.page} form={props.form} userInfo={userInfo} />
				</div>
			</div>
		</div>
	)
}

export default Purchase;
