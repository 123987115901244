import { useContext, useEffect, useState } from "react";
import Cookies from "universal-cookie";
import {
  cleverLogIn,
  getStudentSubscription,
  userGetMe,
} from "@api/rifService";
import UserProvider from "@context/user";
import Footer from "../../components/Footer/Footer";
import {
  SKYBRARY_CLEVER_DISTRICT_TOKEN,
  SKYBRARY_CLEVER_INTERNAL_SECTION_ID,
  SKYBRARY_CLEVER_TOKEN,
  SKYBRARY_MAILTO,
} from "@utils/constants";
import { LogoutHelper } from "@utils/helpers";
import PageSpinner from "../../components/PageSpinner";
import ErrorMessage from "../../components/Clever/ErrorMessage";
import LoadingMessage from "../../components/Clever/LoadingMessage";
import SelectSection from "../../components/Clever/SelectSection";
import TextError from "@components/TextError";
import { useCleverContext } from "@context/clever";

const cookies = new Cookies();

const Clever = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const codeFromParams = queryParams.get("code");
  const userContext = useContext(UserProvider);
  const cleverContext = useCleverContext();
  const [isLoading, setIsLoading] = useState(true);
  const [code, setCode] = useState<string | null>(null);
  const [loginData, setLoginData] = useState(null);
  const [errorMessage, setErrorMessage] = useState<string | React.JSX.Element>(
    "",
  );
  const [studentSubscriptions, setStudentSubscriptions] = useState(null);

  function redirectionForStudent() {
    window.location.replace(process.env.REACT_APP_WEBAPP_DOMAIN);
  }

  function setSectionSelectForStudent(sectionID: any) {
    const cookieParams = {
      path: "/",
      secure: process.env.REACT_APP_COOKIE_DOMAIN !== ".lndo.site",
      sameSite: "lax",
      expires: new Date(loginData.auth.expires_in),
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    };
    userContext.logout();
    cookies.set(SKYBRARY_CLEVER_INTERNAL_SECTION_ID, sectionID, cookieParams);
    userContext.loginCleverStudent(loginData);
    redirectionForStudent();
  }

  function recoverStudentSubscriptions(
    cleverLoginInfo: any,
    cookieParams: any,
  ) {
    console.log("recoverStudentSubscriptions", cleverLoginInfo, cookieParams);
    if (cleverLoginInfo == null && cookieParams == null) {
      LogoutHelper();
    }
    const cleverBearerAccessToken = cleverLoginInfo.user.cleverAccessToken;
    cookies.set(SKYBRARY_CLEVER_TOKEN, cleverBearerAccessToken, cookieParams);
    getStudentSubscription(
      cleverLoginInfo.user.internalCleverUserId,
      cleverLoginInfo.auth.accessToken,
      cleverBearerAccessToken,
    )
      .then((studentSub) =>
        studentSub.status
          ? setStudentSubscriptions(studentSub.data)
          : undefined,
      )
      .catch((_) => setErrorMessage("Student: Problems finding your class."));
  }

  useEffect(() => {
    if (studentSubscriptions !== null && studentSubscriptions.length === 0) {
      setErrorMessage("Student: We did not find any classes for you");
    } else {
      setErrorMessage("");
    }
  }, [studentSubscriptions]);

  /**
   * After we detect a clever teacher, we redirect the user to the skybrary dashboard
   */
  function redirectionForTeacher() {
    window.location.replace(
      `${process.env.REACT_APP_EDU_DOMAIN}/teachers/dashboard`,
    );
  }

  /**
   * Recover the teacher information and set that as usual to then redirect the user to skybrary teacher dashboard
   * @param cleverLoginInfo
   * @param cookieParams
   */
  function loginTeacherHandler(cleverLoginInfo, cookieParams) {
    cookies.set(
      SKYBRARY_CLEVER_TOKEN,
      cleverLoginInfo.user.cleverAccessToken,
      cookieParams,
    );
    userGetMe(cleverLoginInfo.auth.accessToken)
      .then((meData) => {
        console.log("teacher_me", meData);
        if (meData.status) {
          const data = {
            userResponse: meData,
            auth: cleverLoginInfo.auth,
          };
          console.log("data_for_login_teacher", data);
          userContext.login(data);
          userContext.changeLoginFlag();
          redirectionForTeacher();
        } else {
          setErrorMessage("Teacher: Something went wrong. Try again.");
        }
      })
      .catch((_) => {
        setErrorMessage("Teacher: Something went wrong.");
      });
  }

  /**
   * After we detect a clever district admin, we redirect the user to the skybrary dashboard or to the redeem page
   */
  function redirectionForDistrictAdmin() {
    const userData = JSON.parse(localStorage.getItem("summaryData"));
    let validDistrict = true;

    if (cleverContext.getCleverGiftDistrict()) {
      validDistrict =
        cleverContext.getCleverGiftDistrict() === userData.districtId;
    }

    if (cleverContext.giftForClever() && validDistrict) {
      window.location.replace(
        `${process.env.REACT_APP_EDU_DOMAIN}/gift/redeem/subscription-info`,
      );
      console.log("redirect to redeem clever gift page");
    } else {
      window.location.replace(
        `${process.env.REACT_APP_EDU_DOMAIN}/adm/dashboard`,
      );
    }
  }

  function loginDistrictAdminHandler(cleverLoginInfo: any, cookieParams: any) {
    cookies.set(
      SKYBRARY_CLEVER_TOKEN,
      cleverLoginInfo.user.cleverAccessToken,
      cookieParams,
    );
    cookies.set(
      SKYBRARY_CLEVER_DISTRICT_TOKEN,
      cleverLoginInfo.user.districtToken,
      cookieParams,
    );
    userGetMe(cleverLoginInfo.auth.accessToken)
      .then((meData) => {
        console.log("district_admin", meData);
        if (meData.status) {
          const data = {
            userResponse: meData,
            auth: cleverLoginInfo.auth,
          };
          console.log("data_for_login_district_admin", data);
          userContext.login(data);
          userContext.changeLoginFlag();
          console.log("clever_context_dis_ad_handler", cleverContext);
          redirectionForDistrictAdmin();
        } else {
          setErrorMessage("District admin: Something went wrong.");
        }
      })
      .catch((e) => {
        console.error(e);
        setErrorMessage("District admin: Something went wrong.");
      });
  }

  const handleLogin = (loginInfo: any) => {
    setLoginData(loginInfo);
    cleverContext.saveRole(loginInfo.user.type);
    cleverContext.saveSummaryData(loginInfo.user);
    const cookieParams = {
      path: "/",
      secure: process.env.REACT_APP_COOKIE_DOMAIN !== ".lndo.site",
      sameSite: "lax",
      expires: new Date(loginInfo.auth.expiresIn),
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    };
    cookies.remove(SKYBRARY_CLEVER_INTERNAL_SECTION_ID, cookieParams);
    if (cleverContext.isDistrictAdmin() || cleverContext.isDistrictStaff()) {
      loginDistrictAdminHandler(loginInfo, cookieParams);
    }
    if (cleverContext.isStudent()) {
      recoverStudentSubscriptions(loginInfo, cookieParams);
    }
    if (cleverContext.isTeacher()) {
      loginTeacherHandler(loginInfo, cookieParams);
    }
  };

  useEffect(() => {
    // Getting code
    setCode(codeFromParams);
  }, []);

  const somethingWrongError = (
    <div>
      Something went wrong. Try refreshing the page or logging out and logging
      back in. If the issue persists, please contact us on {SKYBRARY_MAILTO} or
      1 (877) RIF-READ for support.
    </div>
  );

  useEffect(() => {
    const loggedInCleverUser = cleverContext.isCleverUser();
    const handleAlreadyLoggedIn = () => {
      const cookieParams = {
        path: "/",
        secure: process.env.REACT_APP_COOKIE_DOMAIN !== ".lndo.site",
        sameSite: "lax",
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      };
      cookies.remove(SKYBRARY_CLEVER_INTERNAL_SECTION_ID, cookieParams);

      if (cleverContext.isDistrictAdmin() || cleverContext.isDistrictStaff()) {
        redirectionForDistrictAdmin();
      }
      if (cleverContext.isStudent()) {
        recoverStudentSubscriptions(loginData, null);
      }
      if (cleverContext.isTeacher()) {
        redirectionForTeacher();
      }
    };

    if (code && !loggedInCleverUser) {
      // With code but not logged in
      cleverLogIn(code)
        .then((cleverLogin) => {
          if (cleverLogin.status) {
            setLoginData(cleverLogin.data);
            handleLogin(cleverLogin.data);
          } else {
            setErrorMessage(cleverLogin.data);
          }
        })
        .catch((_) => {
          setErrorMessage(somethingWrongError);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (loggedInCleverUser) {
      handleAlreadyLoggedIn();
    } else {
      setIsLoading(false);
    }
  }, [code]);

  if (isLoading) return <PageSpinner />;

  return (
    <div className="container d-flex flex-column justify-content-between clever-container">
      <br />
      <div className="row justify-content-center">
        <div className="text-center">
          {!errorMessage && code && studentSubscriptions == null && (
            <LoadingMessage />
          )}
          {!code && (
            <ErrorMessage isCleverLoginError={true}>
              <TextError wrapper="div" text="Clever code not provided." />
            </ErrorMessage>
          )}
          {errorMessage && code && (
            <ErrorMessage isCleverLoginError={true}>
              <TextError wrapper="div" text={errorMessage} />
            </ErrorMessage>
          )}
          {cleverContext.isStudent() &&
            studentSubscriptions !== null &&
            studentSubscriptions?.length !== 0 && (
              <SelectSection
                studentSubscriptions={studentSubscriptions}
                setSectionSelectForStudent={setSectionSelectForStudent}
              />
            )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Clever;
