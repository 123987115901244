import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { editStudentPassword } from "../../api/rifService";
import ChangePasswordForm from "../../components/Forms/parents/ChangePassword";
import TextError from "../../components/TextError";
import UserProvider from "../../context/user";

const ChangePassword = () => {
  const location: any = useLocation();
  const params = useParams();

  const userContext = useContext(UserProvider);
  const navigate = useNavigate();
  let studentHash = params.id;
  const [errorPassword, setErrorPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  useEffect(() => {
    document.title = "Change your Password | Reading Is Fundamental";
  }, []);

  const handleSubmit = async (
    oldPassword: string | object,
    newPassword: string | object
  ) => {
    userContext.setFormProgress(50);
    setErrorPassword(false);
    let oldCleanPass = {
      password: oldPassword.clean_pass,
      passType: oldPassword.pass_type,
    };

    let newCleanPass = {
      password: newPassword.clean_pass,
      passType: newPassword.pass_type,
    };
    const response = await editStudentPassword(
      {
        oldCleanPass: oldCleanPass.password,
        oldPassType: oldCleanPass.passType,
        newCleanPass: newCleanPass.password,
        passType: newCleanPass.passType,
      },
      studentHash
    );

    if (response.status) {
      location.state.data.passwordChanged = true
      setPasswordChanged(true)
      navigate(`/sigin/login/${params.id}`, {
        state: {
          studentsInfo: location.state.data,
        },
      });
    } else {
      setErrorPassword(true);
    }
    userContext.setFormProgress(100);
  };
  return (
    <div className="parents-sign-in change-password">
      {errorPassword && (
        <div className="old-password-error">
          <TextError wrapper="div" text="Your old password is not correct" />
        </div>
      )}
      <ChangePasswordForm
        onSubmit={handleSubmit}
        classHash={location.state.data.classroomHash}
        studentsData={location.state.data}
        studentToken={params.id}
      />
    </div>
  );
};

export default ChangePassword;
