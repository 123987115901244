import { sendEvent } from '../../api/googleAnalyticsService'

/**
 * Small utility to send events to google analytics
 */

const redeemEduGiftCodeEvent = (label: string) => {
  sendEvent({
    'event': 'redeem_code',
    'eventProps': {
      'category': 'form_submit',
      'action': 'School user redeems a redemption code',
      'label': label,
      'value': '0'
    }
  })
}

const registerUserEvent = (label: string) => {
  sendEvent({
      'event': 'sign_up',
      'eventProps': {
        'category': 'form_submit',
        'action': 'School user signs up',
        'label': label,
        'value': '0'
      }
    }
  )
}
const beginCheckout = (promoCode: string, plans: Array<any>, orderTotal: number) => {
  sendEvent({
    event: 'begin_checkout',
    eventProps: {
      coupon: promoCode,
      currency: 'USD',
      items: plans,
      value: orderTotal,
    },
  })
}

const addPaymentInfo = (promoCode: string, plans: Array<any>, orderTotal: number) => {
  sendEvent({
    event: 'add_payment_info',
    eventProps: {
      coupon: promoCode,
      currency: 'USD',
      items: plans,
      payment_type: 'Order Form',
      value: orderTotal,
    },
  })
}

export {
  redeemEduGiftCodeEvent,
  registerUserEvent,
  addPaymentInfo,
  beginCheckout
}
