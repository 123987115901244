import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { classroomInfo } from "../../api/rifService";
import SignInClassroom from "../../components/Parents/SignInClassroom";
import UserContext from "../../context/user";

const ParentSignIn = () => {
  const userContext = useContext(UserContext);
  const params = useParams();
  const [classData, setClassData] = useState({ students: [] });
  const [loading, isLoading] = useState(true);
  const [response, setResponse] = useState({ isExpired: false, validHash: false });
  let parentStudents:any = [];
  const location: any = useLocation();
  let requestConfig: any;
  let userType = "student";
  

  if (location.state === null || location.state.parent) {
    requestConfig = {
      headers: {
        "Skybrary-User-Type": "parent",
      },
    };
    userType = "parent";
  } else if (location.state.teacher) {
    requestConfig = {
      headers: {
        Authorization: `Bearer ${userContext.token}`,
      },
    };
    userType = "teacher";
  } else if (location.state.access) {
    requestConfig = {
      headers: {
        "Skybrary-User-Type": "teacher",
      },
    };
  }

  const getClassroomInfo = async () => {
    let response = await classroomInfo(params.classHash, requestConfig);
    if (response.status) {
      if (userType === 'parent') {
        response.data.data.forEach(
          (classroom) => {
            if (!classroom.expired) {
              classroom.students.forEach(
                (student) => {
                  parentStudents.push(student);
                }
              )
            }
          }
        )
        response.data.data[0].students = parentStudents;
        setResponse({
          isExpired: false, 
          validHash: true 
        });
      } else {
        setResponse({
          isExpired: response.data.data[0].expired, 
          validHash: true 
        });
      }
      setClassData(response.data.data[0]);
    } else {
      setResponse({ 
        isExpired: false, 
        validHash: false 
      })
    }
    isLoading(false);
    userContext.setFormProgress(100);
  }

  useEffect(() => {
    getClassroomInfo();
  }, []);

  return (
    <div className="parents-sign-in">
      {
        (!loading) ? (
          <SignInClassroom
          welcomeName={classData.name}
          students={classData.students}
          hash={params.classHash}
          from={userType}
          classInfo={response}
        />
        ) : (
          <div className="is-loading-sign-in-classroom" />
        ) 
      }
    </div>
  );
};

export default ParentSignIn;
