const GiftSuccessBox = ({ onReload }: { onReload: () => void }) => {
  return (
    <div className="register-form mx-auto">
      <div className="container">
        <h1 className="step-title title-gift">Redeem Your Subscription</h1>
        <div className="gift full-box-information">
          <h2 className="title-box-gift ">code redemption successful</h2>
          <div className="image-container mb-2">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6667 25.5208L21.875 30.7292L33.3333 19.2708M12.5 43.75H37.5C40.9518 43.75 43.75 40.9518 43.75 37.5V12.5C43.75 9.04822 40.9518 6.25 37.5 6.25H12.5C9.04822 6.25 6.25 9.04822 6.25 12.5V37.5C6.25 40.9518 9.04822 43.75 12.5 43.75Z"
                stroke="#3D4751"
                stroke-width="4.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <button
            onClick={onReload}
            className="button btn-sky btn-moderate-navy-blue btn-sky-lg button-gift"
          >
            redeem another code
          </button>
        </div>
      </div>
    </div>
  );
};

export default GiftSuccessBox;
