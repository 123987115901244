/**
 * Used to map the API response with the content needed by the admin dashboard
 * @param collapseSubtitles
 * @returns
 */
const createAdminData = (collapseSubtitles: any) => {
  return {
    manage_account: collapseSubtitles.data.edu_subtitles_messages_adm_manage_account,
    reports: collapseSubtitles.data.edu_subtitles_messages_adm_reports,
    report_classroom: collapseSubtitles.data.edu_subtitles_messages_adm_reports_classroom_N,
    school: collapseSubtitles.data.edu_subtitles_messages_adm_school,
    manage_teacher_help_text: collapseSubtitles.data.edu_subtitles_messages_adm_school_teacher,
    school_clever: collapseSubtitles.data.edu_subtitles_messages_clever_adm_school,
    manage_clever_teacher_help_text: collapseSubtitles.data.edu_subtitles_messages_clever_adm_school_teacher,
  };
};

/**
 * Used to map the API response with the content needed by the parent dashboard
 * @param collapseSubtitles
 * @returns
 */
const createParentData = (collapseSubtitles: any) => {
  return {
    manage: collapseSubtitles.data.edu_subtitles_messages_parent_manage,
    reports: collapseSubtitles.data.edu_subtitles_messages_parent_reports,
  };
};

/**
 * Used to map the API response with the content needed by the teacher dashboard
 * @param dashboardInfo
 * @param collapseSubtitles
 * @returns
 */
const createTeachersData = (dashboardInfo: any, collapseSubtitles: any) => {
  const subtitleData = collapseSubtitles.data
  return {
    start_info_trial: dashboardInfo.teacherTrialStart,
    start_info_normal: dashboardInfo.teacherNormal,
    start_info_multiple: dashboardInfo.teacherStart,
    start_info_clever: dashboardInfo.teacherCleverStart,
    start_info_clever_normal: dashboardInfo.teacherClever,
    featured_content_large_widget: dashboardInfo.featuredContent,
    right_small_widget: dashboardInfo.rightSideContent,
    bottom_large_widget: dashboardInfo.bottomContent,
    links_normal: dashboardInfo.teacherLinkCreatorBlock,
    curriculum: subtitleData.edu_subtitles_messages_teachers_curriculum,
    clever_curriculum: subtitleData.edu_subtitles_messages_clever_teachers_curriculum,
    library: subtitleData.edu_subtitles_messages_teachers_library,
    manage_account: subtitleData.edu_subtitles_messages_teachers_manage_account,
    manage_clever_rosters: subtitleData.edu_subtitles_messages_teachers_manage_clever_rosters,
    manage_rosters: subtitleData.edu_subtitles_messages_teachers_manage_rosters,
    manage_clever_rosters_n: subtitleData.edu_subtitles_messages_teachers_manage_clever_rosters_n,
    manage_rosters_n: subtitleData.edu_subtitles_messages_teachers_manage_rosters_n,
    reports: subtitleData.edu_subtitles_messages_teachers_reports,
    reports_classroom_n: subtitleData.edu_subtitles_messages_teachers_reports_classroom_n,
  };
};

export {
  createAdminData,
  createParentData,
  createTeachersData
}
