import { useEffect } from "react";
import { Link, useLocation, useNavigate} from "react-router-dom";
import Header from "../../components/Parents/Header";

const ParentForgotPassword = () => {
  const location: any = useLocation();
  const navigate: any = useNavigate();
  let teacher = false;

  useEffect (() => {
    document.title = "Forgot your Password? | Reading Is Fundamental";
    if (!location.state) {
      navigate("/student/classroom/access");
    }
  }, [])


  if (!location.state) {
    return <></>
  } else {
    if (location.state.data.userType === "teacher") {
      teacher = true;
    }
    return (
      <div className="parents-sign-in login">
        <div className="parents-container text-center">
          <Header title="Forgot your Password?" subTitle="" from={location.state.data.userType}>
            {" "}
          </Header>
          <div className="forgot-password-text">
            If your forgot your password, your teacher can help you.
          </div>
          <div className="button-wrapper mt-4">
            <Link
              to={`/sigin/login/${location.state.userToken}`}
              state={{
                hash: location.state.data.hash,
                username: location.state.data.username,
                passwordChanged: false,
                studentsInfo: location.state.data,
              }}
              className="btn btn-brandy-punch forgot-password-button"
            >
              OK
            </Link>
          </div>
        </div>
      </div>
    );
  }
};

export default ParentForgotPassword;
