import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SuccessComponent from "../../components/Success";
import { FormPurchaseContext } from "../../context/Forms/purchaseContext";
import { BACK_URL } from "../../utils/constants";
import CleverContext, { CleverProvider } from '../../context/clever'

const Success = (props: { page: string }) => {
  const [showContent, setShowContent] = useState(false);
  const location: any = useLocation();
  let upgradeTrial = false;
  let cleverContext = useContext(CleverContext)

  useEffect(() => {

    if (location.state !== null) {
      if (location.state.upgradeTrial) {
        upgradeTrial = true;
      }

      if (location.state?.showSuccess) {
        setShowContent(true);
        window.history.replaceState({}, document.title);
      }
    } else {
      redirectUser();
    }
  }, []);

  const redirectUser = () => {
    window.location.replace(BACK_URL);
  };

  return (
    <>
      <div className="purchase-page success-page">
        <div className="container">
          <div className="row mx-auto main-content">
            {showContent && (
              <SuccessComponent page={props.page} upgradeTrial={upgradeTrial} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Success;
