import { useEffect } from "react";
import UpgradeSubscriptionForm from "../../../components/Forms/purchase/UpgradeSubscription/UpgradeSubscriptionForm";

const UpgradeSubscription = () => {
    useEffect(() => {
        document.title = "How do you want to purchase ? | Reading Is Fundamental";
    }, []);
    
    return <div className="upgrade-subscription-page"><UpgradeSubscriptionForm /></div>;
};

export default UpgradeSubscription;
