import { useContext, useEffect, useState } from "react";
import UserContext from "../../context/user";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard";
import { Alert } from "react-bootstrap";
import ParentDashboard from "../../components/Dashboard/Parent/ParentSections/Dashboard/ParentDashboard";
import CleverContext from '../../context/clever'
import { isCleverTheme } from "utils/helpers";


function DashboardLayout(props: { from: string }) {
  const userContext = useContext(UserContext);
  const cleverContext = useContext(CleverContext);
  const navigate = useNavigate();


  const paths = [
    "/teachers/pages/help",
    "/teachers/manage/account",
    "/adm/school",
    "/adm/pages/help",
    "/adm/manage",
    "/parent/manage",
    "/parent/pages/help",
    "/adm/checkout",
    "/adm/checkout/card",
    "/adm/checkout/success"
  ];

  if (!userContext.isLoading) {
    if (paths.includes(window.location.pathname)) {
      return <Outlet />;
    }

    if (userContext.showErrorMsg) {
      return(
        <Dashboard rol="">
          <Alert className="text-center mb-0" variant="danger">
            {userContext.errorMsg}
          </Alert>
        </Dashboard>
      );
    }

    if (props.from === "teacher") {

      const UnassignedTeacherDashboard = () => (
        <Dashboard rol="teacher">
          <div className="unnasigned-teacher text-center">
            Assign a classroom to you in order to start using your teacher
            dashboard.
          </div>
        </Dashboard>
      )

      const UnassignedCleverTeacherDashboard = () => (
        <Dashboard rol="teacher">
          <div className="unnasigned-teacher text-center">
              You do not have any classes rostered. Please contact your
              {cleverContext.isCleverUser() ? "Clever District" : "Skybrary"} Administrator
          </div>
        </Dashboard>
      )

      const TeacherExpiredSubscriptionDashboard = () => (
        <Dashboard rol="teacher">
          <p className="teacher-no-subscriptions">
            You don't have any active classroom, contact your school admin.
          </p>
        </Dashboard>
      )

      if (userContext.adminHasActiveSubscription) {
        // User Admin assigned to active classroom
        return <Outlet />;
      }

      if (userContext.userWithSubscription) {
        if (userContext.userData.type === "edu-admin") {
          //User admin
          if (!userContext.adminAssigned) {
            // User Admin unassigned
            return <UnassignedTeacherDashboard/>;
          } else {
            //User admin asigned to expired classroom
            return <TeacherExpiredSubscriptionDashboard />;
          }
        } else {
          //User teacher
          if (userContext.hasActiveSubscription) {
            return <Outlet />
          } else {
            return <TeacherExpiredSubscriptionDashboard />;
          }
        }
      } else {
        //User teacher unassigned
        if (cleverContext.isCleverUser()) {
          return <UnassignedCleverTeacherDashboard/>;
        } else {
          return <UnassignedTeacherDashboard/>;
        }
      }
    }

    if (props.from === "admin") {
      if (cleverContext.isCleverUser()){
          return <Outlet />
      }else{
        if (!userContext.hasActiveSubscription) {
          if (userContext.userData.edu.school.orders.pending) {
            return (
              <Dashboard rol="admin">
                <div className="order-progress">
                  Your Order Form is in progress.
                </div>
              </Dashboard>
            );
          } else {
            return (
              <Dashboard rol="admin">
                <div className="order-progress">
                  You don't have any active subscriptions.{" "}
                  <Link to="/adm/school" className="buy-new-subscriptions">
                    Click here to buy or renew.
                  </Link>
                </div>
              </Dashboard>
            );
          }
        } else {
          return <Outlet />
        }
      }
    }

    if (props.from === "parent") {
      if ((!userContext.hasActiveSubscription || userContext.parentReports.length === 0) &&
        userContext.userData.type === "edu-parent") {
        return (
          <Dashboard rol="parent">
            <div className="order-progress">
              You dont have any ongoing subscription, contact your teacher to get more.
            </div>
          </Dashboard>
        )
      }
      return <Outlet/>
    }
  }

  return (
    <Dashboard rol="loading">
      <div className="loading-info div-spinner-indicator"></div>
    </Dashboard>
  );
}

export default DashboardLayout;
