import ButtonMenu from "@components/ButtonMenu";
import Footer from "@components/Footer/Footer";
import SignIn from "@components/Forms/get-started/Sign-in-form";
import NavBar from "@components/NavBar";
import TextSuccess from "@components/TextSuccess";
import { useCleverContext } from "@context/clever";
import DashboardContext from "@context/dashboardInfo";
import { useUserContext } from "@context/user";
import { isCleverTheme } from "@utils/helpers";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const FormSignIn = () => {
  const userContext = useUserContext();
  const cleverContext = useCleverContext();
  const dashboardContext = useContext(DashboardContext);
  const navigate = useNavigate();
  const location = useLocation();
  let recoverPassword = false;
  let user = "";
  const [passwordRecoverSuccess, setPasswordRecoverSuccess] = useState(false);
  const isClever = useMemo(
    () => isCleverTheme(userContext, cleverContext),
    [userContext, cleverContext],
  );
  const [status, setStatus] = useState(false);

  if (location.state !== null) {
    if (location.state?.recover) {
      recoverPassword = true;
      user = location.state.user;
    }
  }

  const redirectUser = () => {
    const userData = userContext.userData;
    userContext.setFormProgress(100);
    if (userContext.userData.type === "edu-admin") {
      userContext.changeLoginFlag();
      if (
        (!userData.edu.school.orders.pending &&
          userData.edu.school.orders.fromTrial &&
          !userData.edu.user.acceptTOS) ||
        ((userData.edu.user.relatedActive || !userData.edu.user.isTrial) &&
          !userData.edu.user.acceptTOS)
      ) {
        navigate("/set-password");
      } else {
        navigate("/adm/dashboard");
      }
    }
    if (userContext.userData.type === "edu-teacher") {
      userContext.changeLoginFlag();
      if (userData.edu.user.isTrial) {
        userContext.setIsTrialUser(true);
      }
      navigate("/teachers/dashboard");
    }

    if (userContext.userData.type === "edu-parent") {
      userContext.changeLoginFlag();
      navigate("/parent/dashboard");
    }
  };

  const handleSubmit = (event: any) => {
    if (event.status) {
      userContext.setFormProgress(75);
      setStatus(true);
    } else {
      navigate("/user", { state: { errorSignIn: "'" } });
    }
  };

  useEffect(() => {
    document.title = "User Sign In | Reading Is Fundamental";

    if (location.state?.passwordRecoverSuccess) {
      setPasswordRecoverSuccess(true);
    }
  }, []);

  useEffect(() => {
    const userData = userContext.userData;
    const subscriptions = userContext.subscriptions;
    const schoolReports = userContext.schoolReports;
    const eduClever = userData?.edu?.clever;
    if (
      eduClever &&
      dashboardContext.information &&
      userData &&
      subscriptions &&
      schoolReports
    ) {
      redirectUser();
    } else if (userData.userId === null) {
      localStorage.clear();
      sessionStorage.clear();
    }
  }, [
    dashboardContext.information,
    userContext.userData,
    userContext.subscriptions,
    userContext.schoolReports,
  ]);

  useEffect(() => {
    if (
      status &&
      dashboardContext.information &&
      userContext.userData &&
      userContext.subscriptions &&
      userContext.userData &&
      userContext.schoolReports
    ) {
      redirectUser();
    }
  }, [
    dashboardContext.information,
    navigate,
    status,
    userContext.userData,
    userContext.subscriptions,
    userContext.userData,
    userContext.teachers,
    userContext.schoolReports,
  ]);

  return (
    <div className="sign-in-page">
      <NavBar />
      {!isClever && (
        <div className="pt-5">
          <ButtonMenu />
        </div>
      )}
      <div className={`${isClever && "teacher-log-in"}`}>
        {passwordRecoverSuccess && (
          <TextSuccess text="Password Successfully changed!" />
        )}
        <SignIn
          onRecovery={recoverPassword}
          userName={user}
          onSubmit={handleSubmit}
        />
        <Footer />
      </div>
    </div>
  );
};

export default FormSignIn;
