import { useEffect } from "react";
import UpgradeSubscriptionEmailForm from "../../../components/Forms/purchase/UpgradeSubscription/UpgradeSubscriptionEmailForm";

const UpgradeSubscriptionEmail = () => {
    useEffect(() => {
        document.title = "Purchase From Trial - Send Email | Reading Is Fundamental";
    }, []);

    return <div className="upgrade-subscription-page"><UpgradeSubscriptionEmailForm /></div>;
};

export default UpgradeSubscriptionEmail;
