import { useParams } from "react-router-dom";
import FormCheckout from "../../components/Forms/purchase/FormCheckout";
import { formSteps } from "../../context/Forms/purchaseContext";

const Checkout = (props: { form: string; page: string }) => {
  const params = useParams();
  return (
    <div className="purchase-page">
      <div className="container">
        <div className="row mx-auto main-content">
          <FormCheckout
            form={props.form}
            formSteps={formSteps[props.page as keyof Object]}
            renewIds={[]}
            userInfo={params.user}
          />
        </div>
      </div>
    </div>
  );
};

export default Checkout;
