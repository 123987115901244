import CleverRedeemForm from "@components/Forms/gift/Redeem/CleverRedeemForm";
import RedeemForm from "@components/Forms/gift/Redeem/RedeemForm";
import CleverContext from "@context/clever";
import { useContext } from "react";

const Redeem = (props: { page: string }) => {
  const cleverContext = useContext(CleverContext);

  const isClever = cleverContext.isCleverUser();
  const purchaseStyle = isClever ? "" : "purchase-page";

  return (
    <div className={`${purchaseStyle} gift`}>
      <div className="container">
        <div className="row mx-auto main-content">
          {isClever ? (
            <CleverRedeemForm page={props.page} />
          ) : (
            <RedeemForm page={props.page} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Redeem;
