import ButtonMenu from "../../components/ButtonMenu";
import RecoveryPasswordForm from "../../components/Forms/get-started/RecoveryPasswordForm";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer/Footer";

const RecoverPassword = () => {
  return (
    <div className="sign-in-page">
      <NavBar />
      <div className="pt-5">
        <ButtonMenu />
        <RecoveryPasswordForm />
      </div>
      <Footer/>
    </div>
  );
};

export default RecoverPassword;
