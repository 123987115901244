import { verifyEmail } from "@api/rifService";
import CleverSignIn from "@components/Clever/CleverSignIn";
import Header from "@components/header/Header";
import TextError from "@components/TextError";
import { FormPurchaseContext, formSteps } from "@context/Forms/purchaseContext";
import UserProvider from "@context/user";
import { useThemeContext } from "@providers/ThemeProvider";
import { BACK_URL, registerText } from "@utils/constants";
import { registerUserEvent } from "@utils/events/googleAnalitycs";
import { encodeValue } from "@utils/helpers";
import { ErrorMessage, Field, Form, Formik, FormikValues } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link, useLocation } from "react-router-dom";
import Reaptcha from "reaptcha";
import * as Yup from "yup";

const FormRegister = (props: { page: string; form: string; userInfo: any }) => {
  const purchaseContext = useContext(FormPurchaseContext);
  const navigate = useNavigate();
  const previousPath = BACK_URL;
  const userContext = useContext(UserProvider);
  const location = useLocation();
  const pageUrl = location.pathname;
  const query = new URLSearchParams(location.search);
  const showLoginClever =
    process.env.REACT_APP_ENV_NAME === "local" ||
    query.get("clever-test") === "1";
  let active = 1;
  let userEmail = "";
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [submitting, setSubmiting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState(<></>);

  const { setCustomTheme } = useThemeContext()

  const validate = Yup.object({
    email: Yup.string()
      .required("Email Address field is required.")
      .email(
        "The email address entered is not valid. Please double check that there are no special characters present.",
      ),
    confirmEmail: Yup.string()
      .required("Re-enter Email Address field is required.")
      .oneOf([Yup.ref("email")], "Emails do not match"),
    password: Yup.string()
      .required("Password field is required.")
      .min(8, "Password is too short, please use 8 or more characters")
      .matches(
        /(?=.*[a-z])/,
        "Password must contain at least one lowercase character.",
      )
      .matches(
        /(?=.*[A-Z])/,
        "Password must contain at least one uppercase character.",
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords do not match")
      .required("Re-enter Password field is required."),
    agreement: Yup.boolean().oneOf(
      [true],
      "Please agree to the Terms of Use below",
    ),
  });

  if (props.userInfo) {
    userEmail = JSON.parse(atob(props.userInfo)).email;
  }

  const onVerify = (recaptchaResponse: any) => {
    setCaptcha(true);
  };
  const onExpire = () => {
    setCaptcha(false);
  };
  const onError = () => {
    setCaptcha(false);
  };

  if (props.page === "redeem") {
    active = 2;
  }

  const handleSubmit = async (values: FormikValues) => {
    const registerData = {
      email: values.email,
      password: encodeValue(values.password),
      firstName: values.firstName,
      lastName: values.lastName,
    };

    setShowError(false);
    setSubmiting(true);
    if (!captcha) {
      return;
    }
    setButtonDisabled(true);
    userContext.setFormProgress(50);

    const response = await verifyEmail(values.email);
    if (response.status && !response.data.isRegistered) {
      registerUserEvent(pageUrl);
      userContext.setFormProgress(100);
      purchaseContext.setRegisterData(registerData);
      let path = `/${props.page}/info`;
      if (props.page === "redeem") {
        path = `/gift/redeem/school-info`;
      }
      if (props.page === "try_it_free_purchase_email") {
        path = `/purchase/from-trial/mail/${props.userInfo}/checkout`;
      }
      navigate(path);
    } else {
      userContext.setFormProgress(100);
      setSubmiting(false);
      setButtonDisabled(false);
      setShowError(true);
      if (response.data.isRegistered) {
        setErrorText(
          <>
            <span>{`The e-mail address ${values.email} is already registered, you can't create another account with that email. `}</span>
            <Link className="register-error-link" to="/">
              Have you forgotten your password?
            </Link>
          </>,
        );
      } else {
        setErrorText(
          <>
            There was an error, We were not able to validate the credentials,
            please try again
          </>,
        );
      }
    }
  };

  useEffect(() => {
    if (props.page === "redeem") {
      document.title = "Your Info | Reading Is Fundamental";
    } else {
      document.title = "Try It Free | Reading Is Fundamental";
    }
  }, []);

  return (
    <>
      <Header
        steps={formSteps[props.form]}
        activeStep={active}
        previousPath={previousPath}
        buttonText="<< Back"
      />
      <div className="register-form mx-auto">
        <div className="container px-0">
          <div className="row">
            <div className="col">
              <div className="login-form">
                <Formik
                  initialValues={{
                    firstName: "",
                    lastName: "",
                    email: userEmail,
                    confirmEmail: userEmail,
                    password: "",
                    confirmPassword: "",
                    agreement: false,
                  }}
                  validationSchema={validate}
                  onSubmit={handleSubmit}
                  validateOnChange={false}
                  validateOnBlur={false}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <h1 className="step-title text-center">
                        {registerText[props.form].purchaseStepsText[0]}
                        {props.page !== "redeem" && (
                          <Link className="ms-1 sign-in-link" to="/user" onClick={() => setCustomTheme("sky-theme")}>
                            Sign In
                          </Link>
                        )}
                      </h1>
                      {showError && (
                        <div className="register-error">
                          <TextError wrapper="div" text={errorText}></TextError>
                        </div>
                      )}
                      {Object.keys(errors).length > 0 && (
                        <div className="errors">
                          <div className="col-1 p-0 error-logo" />
                          <ul className="p-0 errors-list">
                            <ErrorMessage
                              name="firstName"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.firstName}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="lastName"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.lastName}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="email"
                              component={() => (
                                <TextError wrapper="li" text={errors.email} />
                              )}
                            />
                            <ErrorMessage
                              name="confirmEmail"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.confirmEmail}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="password"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.password}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="confirmPassword"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.confirmPassword}
                                />
                              )}
                            />
                            <ErrorMessage
                              name="agreement"
                              component={() => (
                                <TextError
                                  wrapper="li"
                                  text={errors.agreement}
                                />
                              )}
                            />
                          </ul>
                        </div>
                      )}
                      <div className="step-info text-center">
                        {registerText[props.form].subTitle}
                        <br />
                        <br />
                      </div>
                      {props.userInfo && (
                        <>
                          <div className="mx-0 my-3 row w-100">
                            <label
                              className="form-label col-form-label p-0"
                              htmlFor="firstName"
                            >
                              First Name
                              <span className="form-required"> *</span>
                            </label>
                            <Field
                              className={`form-control ${
                                errors.firstName &&
                                touched.firstName &&
                                "is-invalid"
                              }`}
                              type="text"
                              name="firstName"
                              placeholder=""
                            />
                          </div>
                          <div className="mx-0 my-3 row w-100">
                            <label
                              className="form-label col-form-label p-0"
                              htmlFor="confirmPassword"
                            >
                              Last Name
                              <span className="form-required"> *</span>
                            </label>
                            <Field
                              className={`form-control ${
                                errors.lastName &&
                                touched.lastName &&
                                "is-invalid"
                              }`}
                              type="text"
                              name="lastName"
                              placeholder=""
                            />
                          </div>
                        </>
                      )}
                      <div className="mx-0 my-3 row w-100">
                        <label
                          className="form-label col-form-label p-0"
                          htmlFor="email"
                        >
                          Email Address
                          <span className="form-required"> *</span>
                        </label>
                        <Field
                          className={`form-control ${
                            errors.email && touched.email && "is-invalid"
                          }`}
                          type="email"
                          name="email"
                          placeholder=""
                        />
                      </div>
                      <div className="mx-0 my-3 row w-100">
                        <label
                          className="form-label col-form-label p-0"
                          htmlFor="confirmEmail"
                        >
                          Re-enter Email Address
                          <span className="form-required"> *</span>
                        </label>
                        <Field
                          className={`form-control ${
                            errors.confirmEmail &&
                            touched.confirmEmail &&
                            "is-invalid"
                          }`}
                          type="email"
                          name="confirmEmail"
                          placeholder=""
                        />
                      </div>
                      <div className="mx-0 my-3 row w-100">
                        <label
                          className="form-label col-form-label p-0"
                          htmlFor="password"
                        >
                          Password
                          <span className="form-required"> *</span>
                        </label>
                        <Field
                          className={`form-control ${
                            errors.password && touched.password && "is-invalid"
                          }`}
                          type="password"
                          name="password"
                          placeholder=""
                        />
                      </div>
                      <div className="mx-0 my-3 row w-100">
                        <label
                          className="form-label col-form-label p-0"
                          htmlFor="confirmPassword"
                        >
                          Re-enter Password
                          <span className="form-required"> *</span>
                        </label>
                        <Field
                          className={`form-control ${
                            errors.confirmPassword &&
                            touched.confirmPassword &&
                            "is-invalid"
                          }`}
                          type="password"
                          name="confirmPassword"
                          placeholder=""
                        />
                        <div className="description text-start pe-0">
                          Password must be 8 or more characters and contain one
                          number, a capital, or a special character.
                        </div>
                      </div>
                      <div className="mx-auto my-3 row w-100 terms-of-service text-center">
                        <div className="w-100 px-0">
                          <Field
                            className="form-check-input my-auto p-0 align-middle"
                            type="checkbox"
                            name="agreement"
                            id="agreement"
                          />
                          <label
                            htmlFor="agreement"
                            className="align-middle col-form-label form-check-label p-0 d-inline"
                          >
                            Reading Is Fundamental
                            <a
                              className="tos-link"
                              href="https://skybrary.org/school/terms-of-use"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Terms of Use
                            </a>
                          </label>
                        </div>
                      </div>
                      {submitting && !captcha && (
                        <div className="captcha">
                          <TextError
                            text="Please complete the captcha"
                            wrapper="div"
                          />
                        </div>
                      )}
                      <div className="captcha-container">
                        <Reaptcha
                          className="captcha mt-3"
                          sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITEKEY}
                          onVerify={onVerify}
                          onExpire={onExpire}
                          onError={onError}
                        />
                      </div>
                      <div className="d-grid col-12 mx-auto mb-0">
                        <button
                          className="btn btn-submit btn-brandy-punch btn-spinner-indicator"
                          disabled={buttonDisabled}
                        >
                          {`Next >>`}
                        </button>
                      </div>
                      {showLoginClever && (
                        <div className="d-grid col-12 mx-auto mb-0">
                          <p className="text-center mb-0">
                            --- Do you have a Clever account? ---
                          </p>
                          <CleverSignIn isValidForm={true} />
                        </div>
                      )}
                      <div className="mx-auto my-3 row w-100 terms-of-service text-center">
                        {props.page !== "redeem" ? (
                          <div className="w-100 px-0 privacy-policy">
                            Reading Is Fundamental
                            <a
                              className="existing-account-text ps-1"
                              href="https://skybrary.org/school/privacy-policy?from-edu=1"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Privacy Policy
                            </a>
                          </div>
                        ) : (
                          <div className="w-100 px-0 privacy-policy">
                            <Link
                              className="existing-account-text ps-1"
                              to="/gift/redeem/account-info"
                            >
                              Use an existing account
                            </Link>
                          </div>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormRegister;
