import RedeemAccountInfoForm from "../../../components/Forms/gift/Redeem/AccountInfoForm";
import UserContext from "../../../context/user";
import GiftContext from "../../../context/Forms/giftContext";
import { useContext } from "react";

const AccountInfo = (props: { page: string; }) => {
  const userContext = useContext(UserContext);
  const userData = userContext.userData;
  

  if (userContext.userData.type === "edu-teacher") {
    if (userData.edu.user.isTrial) {
      userContext.setIsTrialUser(true);
    }
  }

  return (
    <div className="purchase-page gift">
      <div className="container">
        <div className="row mx-auto main-content">
          <RedeemAccountInfoForm page={props.page} />
        </div>
      </div>
    </div>
  );
};

export default AccountInfo;
