import { useParams } from "react-router-dom";
import LogIn from "../../components/Parents/LogIn";
import { getClassroomData } from "../../api/rifService";
import { useEffect, useState } from "react";

const ParentLogIn = () => {
  const params: any = useParams();
  const [studentsInfo, setStudentsInfo] = useState([]);

  useEffect(() => {
    getClassroomData(params.userToken).then((response: any) => {
      setStudentsInfo(response.data[0].students);
    });
  }, []);
  return (
    <div className="parents-sign-in login">
      <LogIn
        userToken={params.userToken}
        data={studentsInfo}
      />
    </div>
  );
};

export default ParentLogIn;
