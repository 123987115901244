import ButtonMenu from "../../components/ButtonMenu";
import RecoveryPasswordStepTwo from "../../components/Forms/get-started/RecoveryPasswordStepTwo";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer/Footer";

const RecoverPasswordStepTwo = () => {
  return (
    <div className="sign-in-page">
      <NavBar />
      <div className="pt-5">
        <ButtonMenu />
      </div>
      <RecoveryPasswordStepTwo />
      <Footer/>
    </div>
  );
};

export default RecoverPasswordStepTwo;
