import GiftForm from "../../components/Forms/gift/giftForm"
import { formSteps } from "../../context/Forms/purchaseContext"

const Gift = () => {
  return (
    <div className="purchase-page gift">
      <div className="container">
				<div className="row mx-auto main-content">
					<GiftForm formSteps={formSteps.gift} showHeader={true} />
				</div>
			</div>
    </div>
  );
};

export default Gift
